import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Scalling.module.css'
import { createPortal } from 'react-dom'
import { MdSmartDisplay } from 'react-icons/md'
import laptopimage from '../../img/Home/laptop5.webp'
import tv from '../../img/Home/tv7.webp'
import Ipad from '../../img/Home/Component30.webp'
import arrow from '../../img/Flaticon/arrow.webp'
import arrowRight from '../../img/Flaticon/arrowright.webp'
import ScrollAnimation from '../Animation/ScrollAnimation'
import { motion } from 'framer-motion'
import Modal from '../../components/Modal/Modal'

const Scalling = () => {
    const [modalOpen, setModalOpen] = useState(false)

    // eslint-disable-next-line
    const [src, setSrc] = useState(
        '//cdn-cf-east.streamable.com/video/mp4/oea9wv.mp4?Expires=1697165580&Signature=Y-rQB1ajgQYkauId0kN88dxRWOS2JPx0cyZkI4~o2w6PYBSe8Xyd9OOZWphjyOWx0y188PcCfgMHDXWxaVGIUIt9xiKYmaw~2QhdnI0JUvZNgEuVdmP40XAmm6zpefjAtMiyQYUG0Dr-QLC8Min~Xt3JiZrl3sbeJ2QQJXikOjiCSeYmvGI6eqoheumDMM~aMpVL0xnAQt~7hNYHQ1OIglSoFYM9W7TKsmlsSiE5hmv5sRGRVywqf9ZfsFowBpgwxa1nMGGpuZ10~Smq~EP9WtTAL91OByPn9DXJQL-mGSWGTiPVa2BBLhI1ZEUmL5Pr4PRDMBatsrQX9U6TA2OqdQ__&Key-Pair-Id=APKAIEYUVEN4EVB2OKEQ'
    )
    const handleButtonClick = (value) => {
        setModalOpen(false)
    }
    // eslint-disable-next-line
    const handleVideoClick = (value) => {
        setModalOpen(true)
    }
    return (
        <div className={styles.outerDiv}>
            {/* modal */}
            {modalOpen &&
                createPortal(
                    <Modal
                        onClose={handleButtonClick}
                        onSubmit={handleButtonClick}
                        onCancel={handleButtonClick}
                    >
                        <div className={styles.modalDiv}>
                            <>
                                <iframe
                                    title="video1"
                                    src="https://fast.wistia.net/embed/iframe/397rbvz37q?seo=true&videoFoam=true?autoplay=true"
                                    frameBorder="0"
                                    className={styles.modalVideo}
                                    allowFullScreen
                                    allow="autoplay"
                                    controls="0"
                                    // mute="1"
                                />
                            </>
                        </div>
                    </Modal>,
                    document.body
                )}
            {/* modal */}
            <div className={styles.ScalingContent}>
                <div className={styles.heading}>
                    <ScrollAnimation>
                        <h4>Built For Scale</h4>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>
                            The World's Most Unwaveringly Successful Platform
                            For Quickly Growing Companies.
                        </h1>
                    </ScrollAnimation>
                    <motion.p
                        initial={{ opacity: 0, y: 75 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                    >
                        Growing a business is hard. We make it a whole lot
                        easier, more predictable, less stressful, and more fun.
                        Skip the guesswork, trial-and-error and never-ending
                        shit show of trying to figure everything out yourself –
                        for the very first time. And instead, grow your business
                        with proven client-getting-funnels , frameworks and
                        scientific customer acquisition (not hopes and prayers).
                    </motion.p>
                </div>

                <div className={styles.images}>
                    <img
                        className={styles.laptopimg}
                        src={laptopimage}
                        alt="laptop"
                    />
                    <div className={styles.tvdiv}>
                        {/* <div className={styles.Video} style={{ width: "100%", height: "0px", position: "relative", paddingBottom: "56.250%" }}> */}
                        <iframe
                            title="video1"
                            src="https://streamable.com/e/48p9fc?autoplay=1"
                            frameBorder="0"
                            width="100%"
                            height="100%"
                            allowFullScreen
                            allow="autoplay"
                            mute="1"
                            className={styles.Video}
                            onClick={() => setModalOpen(true)}
                        ></iframe>
                        <div
                            className={styles.videoButton}
                            onClick={() => setModalOpen(true)}
                        >
                            {/* <AiOutlinePlayCircle /> */}
                        </div>
                        <div
                            className={styles.PlayButton}
                            onClick={() => setModalOpen(true)}
                        >
                            <MdSmartDisplay />
                        </div>
                        {/* </div> */}
                        {/* <video className={styles.Video} src={src} controls autoPlay muted /> */}
                        <img className={styles.tv} src={tv} alt="tv" />
                    </div>
                    <img className={styles.ipadimg} src={Ipad} alt="ipad" />
                </div>
                <div className={styles.StyledButton}>
                    <img src={arrow} alt="arrow" />
                    <img src={arrowRight} alt="arrowRight" />
                    <Link to="/StrategySession">
                        <button className={styles.btn} id={styles.btn1}>
                            HIT THE DAMN BUTTON
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Scalling
