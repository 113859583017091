import React, { useRef } from 'react'
import styles from './Contactus.module.css'
import ScrollAnimation from '../Animation/ScrollAnimation'
import emailjs from '@emailjs/browser'

const Contactus = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault()

        emailjs
            .sendForm(
                'service_s81k3zr',
                'template_yrkpu49',
                form.current,
                'OHZFAu3GWK_O5F1jD'
            )
            .then(
                (result) => {
                    // console.log(result.text);
                    alert('Message has been sent!')
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }
    const handleSubmitEmail = (e) => {
        e.preventDefault() // Prevents the default form submission behavior
        sendEmail(e)
        form.current.reset()
    }
    return (
        <div className={styles.Contactus}>
            <div className={styles.contactContainer}>
                <div className={styles.FormSection}>
                    <form ref={form} onSubmit={(e) => handleSubmitEmail(e)}>
                        <div className={styles.gridContainer}>
                            <div className={styles.gridItem}>
                                <input
                                    type="text"
                                    name="Fname"
                                    placeholder="First Name*"
                                    required
                                ></input>
                            </div>
                            <div className={styles.gridItem}>
                                <input
                                    type="email"
                                    name="Email"
                                    placeholder="Email*"
                                    required
                                ></input>
                            </div>
                            <div className={styles.gridItem}>
                                <input
                                    type="text"
                                    name="Subject"
                                    placeholder="Subject"
                                    required
                                ></input>
                            </div>
                            <div className={styles.gridItem}>
                                <input
                                    type="text"
                                    name="Phone"
                                    placeholder="Phone no."
                                ></input>
                            </div>
                            <div
                                className={`${styles.gridItem} ${styles.span2}`}
                            >
                                <textarea
                                    rows="6"
                                    name="Query"
                                    placeholder="TYPE YOUR QUERY"
                                ></textarea>
                            </div>
                            <div
                                className={`${styles.gridItem} ${styles.span2}`}
                            >
                                <button
                                    className={styles.btn}
                                    id={styles.btn1}
                                    type="submit"
                                >
                                    Contact us
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={styles.ImageSection}>
                    <ScrollAnimation style={{ textAlign: 'center' }}>
                        <h4>MORE THAN 1000+ COMPANIES TRUSTED US WORLDWIDE</h4>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>speak with our experts</h1>
                    </ScrollAnimation>
                    <p>
                        Our website design and development professionals will
                        listen to your objectives while providing knowledge
                        gleaned from decades of combined digital marketing
                        experience.
                    </p>
                </div>  
            </div>
        </div>
    )
}

export default Contactus
