import React, { useEffect, useState } from 'react'
import styles from './HeroSection.module.css'
import HeroBg from '../../img/Home/background-himal.webp'
import Person1 from '../../img/Home/person1.jpg'
import Person2 from '../../img/Home/person2.jpg'
import Person3 from '../../img/Home/person3.jpg'
import { FaChevronRight } from 'react-icons/fa6'
import './searchButton.css'
import { useScroll, useTransform, motion, AnimatePresence } from 'framer-motion'
import LogoDark from '../../img/Logos/primary_logo_dark.png'
import { Link } from 'react-router-dom'

const HeroSection = () => {
    // const { scrollY } = useScroll()

    // const translateY = useTransform(scrollY, [0, 300], [0, 100])
    // const opacity = useTransform(scrollY, [0, 600], [1, 0])

    const texts = ['Get You Leads', 'Deliver Growth', 'Manage Brands']
    const [currentIndex, setCurrentIndex] = useState(0)

    // Stagger animation for content spans
    const container = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
    }
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length)
        }, 3000) // Change text every 3 seconds
        return () => clearInterval(interval)
    }, [texts.length])

    return (
        <div className={styles.body}>
            <div className={styles.HeroSection}>
                <img className={styles.heroImage} src={HeroBg} alt="hero_bg" />
                <div className={styles.heroOverlay}></div>
                <div className={styles.container}>
                    <div
                        // style={{ opacity, y: translateY }}
                        className={styles.heroContent}
                    >
                        <div className={styles.heroLogo}>
                            <div className={styles.logo}>
                                <Link to="/">
                                    <img src={LogoDark} alt="logo" />
                                </Link>
                            </div>
                            <div className={styles.strategy__container}>
                                <h4 className={styles.strategy__heading}>We</h4>
                                <div className={styles.nav__circle}></div>
                                <div className={styles.nav__empty}></div>
                                <motion.div
                                    className=""
                                    variants={container}
                                    initial="hidden"
                                    animate="visible"
                                >
                                    <AnimatePresence
                                        mode="wait"
                                        className={styles.strategy__wrapper}
                                    >
                                        <motion.span
                                            key={texts[currentIndex]}
                                            initial={{
                                                opacity: 0,
                                                y: 20,
                                            }}
                                            animate={{
                                                opacity: 1,
                                                y: 0,
                                            }}
                                            exit={{
                                                opacity: 0,
                                                y: -20,
                                            }}
                                            transition={{
                                                duration: 0.2,
                                            }}
                                            className={styles.strategy__text}
                                        >
                                            {texts[currentIndex]}
                                        </motion.span>
                                    </AnimatePresence>
                                </motion.div>
                            </div>
                        </div>
                        <Link to="/reviews">
                            <div className={styles.heroSuccess}>
                                <div className={styles.chipsContainer}>
                                    <img
                                        src={Person1}
                                        alt="person1"
                                        className={styles.chipsImage}
                                    />
                                    <img
                                        src={Person2}
                                        alt="person2"
                                        className={`${styles.chipsImage} ${styles.chipsImageOff}`}
                                    />
                                    <img
                                        src={Person3}
                                        alt="person3"
                                        className={`${styles.chipsImage} ${styles.chipsImageOff}`}
                                    />
                                </div>
                                <p>BECOME OUR NEXT SUCCESS STORY </p>
                                <FaChevronRight className={styles.heroIcon} />
                            </div>
                        </Link>
                        <h1 className={styles.heroTitle}>
                            Extraordinary Digital Experiences
                        </h1>
                        <p className={styles.leadingBrands}>
                            - Leading Brands To Digital
                        </p>
                        <p className={styles.heroPara}>
                            Congrats, you’re here because you’re looking to grow
                            a business. Build a brand. Create something amazing.
                            We’re a digital marketing agency that exists to help
                            business owners achieve their dreams & we can’t wait
                            to hear about yours
                        </p>
                        <div className={styles.heroButtons}>
                            <Link to="/StrategySession">
                                <button className={styles.heroButton}>
                                    Book a Free Consultation
                                </button>
                            </Link>
                            {/* <button className={styles.heroButton}>
                                SEO Package
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <ScrollTopDiv>
                <div className={styles.Brands}>
                    <div className={styles.Brandstitle}>
                        <ScrollAnimation>
                            <p>Collaborated</p>
                        </ScrollAnimation>
                        <ScrollAnimation>
                            <h1>Brand Experience</h1>
                        </ScrollAnimation>
                    </div>
                    <div className={styles.gridContainer}>
                        <div className={styles.gridItem}>
                            <img src={Client1} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client7} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client4} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client6} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client5} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client2} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client8} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client9} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client10} alt="client1" />
                        </div>
                        <div className={styles.gridItem}>
                            <img src={Client3} alt="client1" />
                        </div>
                    </div>
                </div>
            </ScrollTopDiv> */}
        </div>
    )
}

export default HeroSection
