import React from 'react'
import styles from './Footer.module.css'
import Logo from '../img/Logos/primary_logo.png'
import Australia from '../img/Logos/australia1.webp'
import Macfree from '../img/Logos/mcafee.webp'
import SecureSSL from '../img/Logos/securessl.webp'
import Privacy from '../components/Privacy.pdf'
import Terms from '../components/TermsandCondition.pdf'
import Refund from '../components/RefundPolicy.pdf'

import component from '../img/Logos/Component6.webp'
import * as FaIcons from 'react-icons/fa6'
import * as IoIcons from 'react-icons/io5'
import * as IoIconss from 'react-icons/io'
import { useLocation } from 'react-router'
import Marquee from 'react-fast-marquee'

const Footer = () => {
    const location = useLocation()
    const handleDownloadPrivacyPDF = () => {
        const link = document.createElement('a')
        link.href = Privacy
        link.download = 'Privacy.pdf' // You can specify the desired filename here
        link.click()
    }
    const handleDownloadTermsPDF = () => {
        const link = document.createElement('a')
        link.href = Terms
        link.download = 'TermsandCondition.pdf' // You can specify the desired filename here
        link.click()
    }
    const handleDownloadRefundPDF = () => {
        const link = document.createElement('a')
        link.href = Refund
        link.download = 'RefundPolicy.pdf' // You can specify the desired filename here
        link.click()
    }
    let locationString1 = location.pathname.slice(1)
    const shouldShowNavbar = locationString1.includes('StrategySession')

    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ]
    const today = days[new Date().getDay()]

    return (
        <>
            {!shouldShowNavbar && (
                <div className={styles.Footer}>
                    <div className={styles.container}>
                        {/* <div className={styles.IntroSection}>
                            <img src={Logo} alt="logo" />
                            <p>
                                ©Except as permitted by the copyright law
                                applicable to you, you may not reproduce or
                                communicate any of the content on this website,
                                including files downloadable from this website,
                                without the permission of the copyright owner.
                            </p>
                            <p>ACN : 628 755 055</p>
                            <p>ABN : 97 628 755 055</p>
                            <div className={styles.pictures}>
                                <img src={Australia} alt="aus" />
                                <img src={Macfree} alt="mac" />
                                <img src={SecureSSL} alt="securessl" />
                            </div>
                        </div>
                        <div className={styles.LeftSection}>
                            <h2>Quick Links</h2>
                            <div>
                                <a href="/">Home</a>
                            </div>
                            <div>
                                <a href="/about">About </a>
                            </div>
                            <div>
                                <a href="/services">Services</a>
                            </div>
                            <div>
                                <a href="/contact">Contact</a>
                            </div>
                            <div>
                                <a
                                    href="https://www.murphystechnology.com.au/pdf/Refund%20Policy.pdf"
                                    target="_blank"
                                >
                                    Refund Policy
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.murphystechnology.com.au/pdf/Terms%20and%20Condition.pdf"
                                    target="_blank"
                                >
                                    Terms and Conditions
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.murphystechnology.com.au/pdf/Privacy.pdf"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                        <div className={styles.RightSection}>
                            <div>
                                <h2>Contact</h2>
                                <div className={styles.contactDetails}>
                                    <FaIcons.FaLocationDot />
                                    <div>
                                        <p style={{ margin: '0' }}>
                                            Unit 46 162/170 Parramatta Road,
                                        </p>
                                        <p style={{ margin: '0' }}>
                                            {' '}
                                            Homebush NSW 2140
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.contactDetails}>
                                    <IoIcons.IoCallSharp />
                                    <p>
                                        <a href="tel:02 7254 4827">
                                            02 7254 4827
                                        </a>{' '}
                                        (MAIN LINE)
                                        <br />
                                        <a href="tel:0485 931 114">
                                            0485 931 114
                                        </a>{' '}
                                        (HOT LINE)
                                    </p>
                                </div>

                                <div className={styles.contactDetails}>
                                    <IoIconss.IoMdMail />
                                    <p>
                                        <a href="mailto:info@murphystechnology.com">
                                            info@murphystechnology.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className={styles.Services}>
                                <h2>Trending Platforms</h2>
                                <img src={component} alt="services" />
                            </div>
                        </div> */}

                        <div className={styles.footerContent}>
                            <div class={styles.footerMenu}>
                                <p className={styles.footerTitle}>
                                    Speak to us
                                </p>
                                <div class={styles.footerContactDetails}>
                                    <i
                                        class="fa-solid fa-phone"
                                        aria-hidden="true"
                                    ></i>
                                    <div className={styles.footerPhone}>
                                        <a href="tel:0272544827">
                                            02 7254 4827 (MAIN LINE)
                                        </a>
                                        <a href="tel:0485931114">
                                            0485 931 114 (HOT LINE)
                                        </a>
                                    </div>
                                </div>
                                <div class={styles.footerContactDetails}>
                                    <i
                                        class="fa-solid fa-envelope"
                                        aria-hidden="true"
                                    ></i>
                                    <a href="mailto:info@murphystechnology.com">
                                        info@murphystechnology.com
                                    </a>
                                </div>

                                <div class={styles.footerContactDetails}>
                                    <i
                                        class="fa-solid fa-location-dot"
                                        aria-hidden="true"
                                    ></i>

                                    <a
                                        className={styles.footerLocation}
                                        href="https://maps.app.goo.gl/5M6voJ8HBVkh1i7x5"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Unit 46 162/170 Parramatta Road,
                                        Homebush NSW 2140
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className={styles.footerPictures}>
                                    <img src={Australia} alt="aus" />
                                    <img src={Macfree} alt="mac" />
                                    <img src={SecureSSL} alt="securessl" />
                                </div>
                                <div className={styles.footerSocial}>
                                    <p>
                                        <a
                                            href="https://www.instagram.com/murphystechnology/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Instagram
                                        </a>
                                    </p>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13"
                                        height="14"
                                        fill="none"
                                        viewBox="0 0 13 14"
                                        class="FooterSimpleContents_footer-socials__separator__8jE_R"
                                    >
                                        <path
                                            fill="currentColor"
                                            fill-rule="evenodd"
                                            d="M7.121.87H5.874v4.123L2.96 2.078l-.882.882 2.92 2.919H.864v1.247h4.133l-2.919 2.919.882.882 2.913-2.913v4.122h1.247V8.004l2.923 2.923.882-.882-2.919-2.919h4.125V5.88H8.009l2.919-2.919-.882-.882-2.925 2.925V.869Z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                    <p>
                                        <a
                                            href="https://www.facebook.com/murphysworld80/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Facebook
                                        </a>
                                    </p>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13"
                                        height="14"
                                        fill="none"
                                        viewBox="0 0 13 14"
                                        class="FooterSimpleContents_footer-socials__separator__8jE_R"
                                    >
                                        <path
                                            fill="currentColor"
                                            fill-rule="evenodd"
                                            d="M7.121.87H5.874v4.123L2.96 2.078l-.882.882 2.92 2.919H.864v1.247h4.133l-2.919 2.919.882.882 2.913-2.913v4.122h1.247V8.004l2.923 2.923.882-.882-2.919-2.919h4.125V5.88H8.009l2.919-2.919-.882-.882-2.925 2.925V.869Z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                    <p>
                                        <a
                                            href="https://www.linkedin.com/company/murphys-technology/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            LinkedIn
                                        </a>
                                    </p>
                                </div>
                                <div className={styles.footerAbn}>
                                    <p>ACN : 628 755 055</p>
                                    <p>ABN : 97 628 755 055</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footerName}>
                        <Marquee
                            className="footerMarqueeContainer"
                            direction="left"
                            speed={150}
                            style={{ overflowY: 'hidden' }}
                        >
                            <h2 className={styles.footerNameText}>
                                <span>M</span>URPHYS &nbsp;
                            </h2>
                            <h2 className={styles.footerNameText}>
                                <span
                                    className={styles.footerTextTech}
                                    style={{ paddingRight: '20px' }}
                                >
                                    T
                                </span>
                                ECHNOLOGY&nbsp;
                            </h2>
                        </Marquee>
                    </div>
                    <div className={styles.copyright}>
                        <p>
                            Copyright © Murphys Technology Pty Ltd{' '}
                            {new Date().getFullYear()}. All Rights Reserved.
                            Except as permitted by copyright law, you may not
                            reproduce or share any content from this website,
                            including downloadable files, without permission.
                            Enjoy the rest of your {today}!
                        </p>
                        <div className={styles.footerTerms}>
                            <div>
                                <a
                                    href="https://www.murphystechnology.com.au/pdf/Refund%20Policy.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Refund Policy
                                </a>
                            </div>
                            <div>|</div>
                            <div>
                                <a
                                    href="https://www.murphystechnology.com.au/pdf/Terms%20and%20Condition.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms and Conditions
                                </a>
                            </div>
                            <div>|</div>
                            <div>
                                <a
                                    href="https://www.murphystechnology.com.au/pdf/Privacy.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Footer
