import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import ScrollToTop from './pages/ScrollToTop/ScrollToTop'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home/Home'
import About from './pages/About/About'
import Process from './pages/Process/Process'
import Contact from './pages/Contact/Contact'
import Career from './pages/Career/Career'
import FAQ from './pages/FAQ/FAQ'
import Services from './pages/Services/Services'
import WebService from './pages/Services/WebService'
import DigitalMarketing from './pages/Services/DigitalMarketing'
import Graphics from './pages/Services/Graphics'
import MobileApp from './pages/Services/MobileApp'
import ActualCareer from './pages/ActualCareer/ActualCareer'
import StrategySession from './components/Survey/StrategySession'
import Reviews from './pages/Reviews/Reviews'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import './App.css'
import Strategy from './pages/Strategy/Strategy'
import Navbar2 from './components/Navbar2'

function App() {
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
    }, [])
    return (
        <BrowserRouter>
            <ScrollToTop />

            <Routes>
                {/* Routes with Navbar and Footer */}
                <Route
                    path="/"
                    element={
                        <>
                            <Navbar2 />
                            <Home />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/about"
                    element={
                        <>
                            <Navbar />
                            <About />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/process"
                    element={
                        <>
                            <Navbar />
                            <Process />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/contact"
                    element={
                        <>
                            <Navbar />
                            <Contact />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/reviews"
                    element={
                        <>
                            <Navbar />
                            <Reviews />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/services"
                    element={
                        <>
                            <Navbar />
                            <Services />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/webservice"
                    element={
                        <>
                            <Navbar />
                            <WebService />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/digitalMarketing"
                    element={
                        <>
                            <Navbar />
                            <DigitalMarketing />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/graphics"
                    element={
                        <>
                            <Navbar />
                            <Graphics />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/mobileapp"
                    element={
                        <>
                            <Navbar />
                            <MobileApp />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/career"
                    element={
                        <>
                            <Navbar />
                            <ActualCareer />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/affiliates"
                    element={
                        <>
                            <Navbar />
                            <Career />
                            <Footer />
                        </>
                    }
                />
                <Route
                    path="/FAQ"
                    element={
                        <>
                            <Navbar />
                            <FAQ />
                            <Footer />
                        </>
                    }
                />

                {/* Routes without Navbar and Footer */}
                {/* <Route path="/packages" element={<Strategy />} /> */}
                <Route path="/StrategySession" element={<StrategySession />} />
                <Route
                    path="/StrategySession/:id"
                    element={<StrategySession />}
                />
            </Routes>
        </BrowserRouter>
    )
}

export default App
