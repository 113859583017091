import React, { useState, useRef } from 'react'
import styles from './Contact.module.css'
import { IconContext } from 'react-icons'
import { FaLocationDot } from 'react-icons/fa6'
import { CiMail } from 'react-icons/ci'
import { IoCallSharp } from 'react-icons/io5'
import { Helmet } from 'react-helmet'
// import FAQ from '../../components/FAQ'
import GetAQuote from '../../components/GetAQuote'

import { useNavigate } from 'react-router'
// import ReCAPTCHA from "react-google-recaptcha";
// import TopBanner from '../../components/TopBanner/TopBanner'
import emailjs from '@emailjs/browser'
import ScrollAnimation from '../../components/Animation/ScrollAnimation'
import TopBanner2 from '../../components/TopBanner/TopBanner2'

const Contact = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault()

        emailjs
            .sendForm(
                'service_s81k3zr',
                'template_yrkpu49',
                form.current,
                'OHZFAu3GWK_O5F1jD'
            )
            .then(
                (result) => {
                    // console.log(result.text);
                    alert('Message has been sent!')
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }
    const handleSubmitEmail = (e) => {
        e.preventDefault() // Prevents the default form submission behavior
        sendEmail(e)
        form.current.reset()
    }
    // eslint-disable-next-line
    const [recaptchaValue, setRecaptchaValue] = useState(null)
    // eslint-disable-next-line
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title></title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
            </Helmet>
            <div className={styles.ContactDiv}>
                {/* <TopBanner name="Contact" /> */}
                <TopBanner2 name="Contact" />
                <div className={styles.FirstDiv}>
                    <div className={styles.InnerFirstDiv}>
                        <div className={styles.textDiv}>
                            <ScrollAnimation>
                                <h4>Keep In Touch</h4>
                            </ScrollAnimation>
                            <ScrollAnimation>
                                <h3>
                                    FIND OUT HOW WE CAN INCREASE YOUR REVENUE.
                                </h3>
                            </ScrollAnimation>
                            <ScrollAnimation>
                                <p>
                                    Looking for an Online Marketing Agency who
                                    does what it takes, every time? Well, we’re
                                    the agency you don’t want working for your
                                    competition – so simply fill in the form
                                    below and we’ll be in touch with you
                                    shortly.
                                </p>
                            </ScrollAnimation>
                        </div>
                        <div className={styles.container}>
                            <form
                                className={styles.Form}
                                ref={form}
                                onSubmit={(e) => handleSubmitEmail(e)}
                            >
                                <input
                                    type="text"
                                    name="Fname"
                                    placeholder="First Name*"
                                    required
                                ></input>
                                <input
                                    type="email"
                                    name="Email"
                                    placeholder="Email*"
                                    required
                                ></input>
                                <input
                                    type="text"
                                    name="Subject"
                                    placeholder="Subject"
                                    required
                                ></input>
                                <input
                                    type="text"
                                    name="Phone"
                                    placeholder="Phone no."
                                    required
                                ></input>
                                <textarea
                                    rows="7"
                                    name="Query"
                                    placeholder="Message"
                                />
                                {/* <ReCAPTCHA
                            sitekey="6LeYJR8oAAAAAAmU9pxCkd8o7353yrOcKYRIgJnu"
                            onChange={(value) => setRecaptchaValue(value)}
                        /> */}
                                <button
                                    className={styles.btn}
                                    id={styles.btn1}
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </form>
                            <div className={styles.Info}>
                                <ScrollAnimation>
                                    <h3>CONTACT INFO</h3>
                                </ScrollAnimation>
                                <ScrollAnimation>
                                    <h4>
                                        Have any Queries? Let us know. We will
                                        clear it for you at the best.
                                    </h4>
                                </ScrollAnimation>
                                <IconContext.Provider value={{ size: '30px' }}>
                                    <ScrollAnimation>
                                        <div className={styles.gridItem}>
                                            <FaLocationDot />
                                            <div className={styles.text}>
                                                <h6>Office</h6>
                                                <p>
                                                    Unit 46 162/170 Parramatta
                                                    Road,
                                                </p>
                                                <p>Homebush NSW 2140</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                    <ScrollAnimation>
                                        <div className={styles.gridItem}>
                                            <CiMail />
                                            <div className={styles.text}>
                                                <h6>EMAIL</h6>
                                                <a href="mailto: info@murphystechnology.com">
                                                    info@murphystechnology.com
                                                </a>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                    <ScrollAnimation>
                                        <div className={styles.gridItem}>
                                            <IoCallSharp />
                                            <div className={styles.text}>
                                                <h6>
                                                    MAIN LINE (FOR BILLING /
                                                    TECHNICAL SUPPORT)
                                                </h6>
                                                <a
                                                    href="tel:02 7254 4827 "
                                                    className={styles.call}
                                                >
                                                    02 7254 4827 |
                                                </a>
                                                <a
                                                    href="tel:0485 931 114"
                                                    className={styles.call}
                                                >
                                                    {' '}
                                                    0485 931 114
                                                </a>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </IconContext.Provider>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={styles.ContactInfo}>
                <div className={styles.InnerContactInfo}>
                    <div className={styles.Icon}>
                        <BsTelephoneFill />
                    </div>
                    <h1>Phone</h1>
                    <div>
                        <p>0123-456-7890</p>
                        <p>0123-456-7890</p>
                    </div>
                </div>
                <div className={styles.InnerContactInfo}>
                    <div className={styles.Icon}>
                        <GrMail />
                    </div>
                    <h1>Email</h1>
                    <div>
                        <p>info@example.com</p>
                        <p>support@example.com</p>
                    </div>
                </div>
                <div className={styles.InnerContactInfo}>
                    <div className={styles.Icon}>
                        <FaLocationArrow />
                    </div>
                    <h1>Address</h1>
                    <div>
                        <p>No: 58 A, East Madison Street,
                        </p>
                        <p>Baltimore, MD, USA 4508</p>
                    </div>
                </div>
            </div> */}
                <div className={styles.mapDiv}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.9920406273786!2d151.07677259678954!3d-33.86409599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bbd91afdc181%3A0xb17c84cb35b6c196!2sMurphys%20Technology%20Pty%20Ltd!5e0!3m2!1sen!2sus!4v1694570475283!5m2!1sen!2sus"
                        width="100%"
                        height="600"
                        style={{ border: '0' }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="maps"
                    ></iframe>
                </div>
                <GetAQuote />
            </div>
        </>
    )
}

export default Contact
