import React, { useState, useEffect, useRef } from 'react'
import { useLocation, Link, NavLink } from 'react-router-dom'
import NavbarAnimation from './Animation/NavbarAnimation'
import styles from './Navbar2.module.css'
// import * as FaIcons from "react-icons/fa6";
import * as AiIcons from 'react-icons/ai'
import * as BsIcons from 'react-icons/bs'
import * as IoIcons from 'react-icons/io5'
import * as BiIcons from 'react-icons/bi'
import { IconContext } from 'react-icons'
import Logo from '../img/Logos/primary_logo.png'
import menuWhite from '../img/Flaticon/menu-white.png'
import menuBlue from '../img/Flaticon/menu-blue.png'
import LogoDark from '../img/Logos/primary_logo_dark.png'

import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs'
import { AnimatePresence, useInView, motion } from 'framer-motion'
// import { createPortal } from 'react-dom'

const Navbar2 = () => {
    const [sidebar, setSidebar] = useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const location = useLocation()

    let locationString1 = location.pathname.slice(1)
    const shouldShowNavbar = locationString1.includes('StrategySession')

    const ref = useRef()
    const isInView = useInView(ref)

    const [uparrow, setuparrow] = useState(false)

    useEffect(() => {
        if (isInView) {
            setuparrow(true)
        }
    }, [isInView, uparrow])
    useEffect(() => {
        let locationString = location.pathname.slice(1)
        if (!locationString) {
            locationString = 'home'
        }
        const str2 =
            locationString.charAt(0).toUpperCase() + locationString.slice(1)
        // const str2 = locationString.toUpperCase();
        let titleString = "Murphy's Technology - " + str2
        document.title = titleString
    }, [location.pathname])

    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [dropdownHelp, setDropdownHelp] = useState(false)
    const [dropdownwho, setDropdownwho] = useState(false)

    // eslint-disable-next-line
    const handleDropdownCclick = (e) => {
        setDropdownVisible(!dropdownVisible)
        if (dropdownVisible) {
            e.target.classList.add(styles.color)
        } else {
            e.target.classList.remove(styles.color)
        }
    }
    // eslint-disable-next-line
    const handleClickHelp = (e) => {
        setDropdownHelp(!dropdownHelp)
        if (dropdownHelp) {
            e.target.classList.add(styles.color)
        } else {
            e.target.classList.remove(styles.color)
        }
    }
    // eslint-disable-next-line
    const handleClickWho = (e) => {
        setDropdownwho(!dropdownwho)
        if (dropdownwho) {
            e.target.classList.add(styles.color)
        } else {
            e.target.classList.remove(styles.color)
        }
    }

    const changecolor = () => {
        const scrollbarDiv = document.querySelector('#ScrollBar')
        if (scrollbarDiv) {
            if (window.scrollY > 600 && scrollbarDiv) {
                scrollbarDiv.classList.add(styles.ShowOnScroll)
            } else {
                scrollbarDiv.classList.remove(styles.ShowOnScroll)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changecolor)
        return () => {
            window.removeEventListener('scroll', changecolor)
        }
    }, [])

    // Stagger animation for content spans
    const container = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
    }
    const texts = ['Get You Leads', 'Deliver Growth', 'Manage Brands']
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length)
        }, 3000) // Change text every 3 seconds
        return () => clearInterval(interval)
    }, [texts.length])

    return (
        <>
            {!shouldShowNavbar && (
                <div className={styles.topHeader}>
                    <header>
                        <nav className={`${styles.topmenu} ${styles.fixed}`}>
                            <div className={styles.navbar}>
                                <div className={styles.logo}>
                                    <Link to="/">
                                        <img src={LogoDark} alt="logo" />
                                    </Link>
                                </div>

                                <div className={styles.text}>
                                    <Link
                                        to="/"
                                        className={
                                            location.pathname === '/'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        HOME
                                    </Link>
                                    <Link
                                        to="/about"
                                        className={
                                            location.pathname.slice(1) ===
                                            'about'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        ABOUT
                                    </Link>
                                    <Link
                                        to="/services"
                                        className={
                                            location.pathname.slice(1) ===
                                            'services'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        SERVICES
                                    </Link>
                                    {/* <a
                                        href="/packages"
                                        className={
                                            location.pathname.slice(1) ===
                                            'affiliates'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        PACKAGES
                                    </a> */}
                                    <Link
                                        to="/affiliates"
                                        className={
                                            location.pathname.slice(1) ===
                                            'affiliates'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        AFFILIATES
                                    </Link>
                                    <Link
                                        to="/reviews"
                                        className={
                                            location.pathname.slice(1) ===
                                            'reviews'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        REVIEWS
                                    </Link>
                                    <Link
                                        to="/process"
                                        className={
                                            location.pathname.slice(1) ===
                                            'process'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        PROCESS
                                    </Link>
                                    <Link
                                        to="career"
                                        className={
                                            location.pathname.slice(1) ===
                                            'career'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        CAREER
                                    </Link>

                                    <div className={styles.dropdownContainer}>
                                        <Link
                                            className={
                                                location.pathname.slice(1) ===
                                                    'contact' ||
                                                location.pathname.slice(1) ===
                                                    'FAQ'
                                                    ? `${styles.navitem} ${styles.navitemactive}`
                                                    : styles.navitem
                                            }
                                        >
                                            HELP
                                        </Link>
                                        <div className={styles.dropdownContent}>
                                            <Link
                                                to="/contact"
                                                className={styles.dropdownItem}
                                            >
                                                CONTACT
                                            </Link>
                                            <Link
                                                to="/FAQ"
                                                className={styles.dropdownItem}
                                            >
                                                FAQ
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                {location.pathname === '/' ? (
                                    <div className={styles.strategy__container}>
                                        <h4
                                            className={styles.strategy__heading}
                                        >
                                            We
                                        </h4>
                                        <div
                                            className={styles.nav__circle}
                                        ></div>
                                        <div
                                            className={styles.nav__empty}
                                        ></div>
                                        <motion.div
                                            className=""
                                            variants={container}
                                            initial="hidden"
                                            animate="visible"
                                        >
                                            <AnimatePresence
                                                mode="wait"
                                                className={
                                                    styles.strategy__wrapper
                                                }
                                            >
                                                <motion.span
                                                    key={texts[currentIndex]}
                                                    initial={{
                                                        opacity: 0,
                                                        y: 20,
                                                    }}
                                                    animate={{
                                                        opacity: 1,
                                                        y: 0,
                                                    }}
                                                    exit={{
                                                        opacity: 0,
                                                        y: -20,
                                                    }}
                                                    transition={{
                                                        duration: 0.2,
                                                    }}
                                                    className={
                                                        styles.strategy__text
                                                    }
                                                >
                                                    {texts[currentIndex]}
                                                </motion.span>
                                            </AnimatePresence>
                                        </motion.div>
                                    </div>
                                ) : (
                                    <div className={styles.strategy__container}>
                                        <div
                                            className={styles.nav__empty}
                                        ></div>
                                    </div>
                                )}

                                <div className={styles.menubars}>
                                    {/* <FaIcons.FaBars onClick={showSidebar} /> */}
                                    <img
                                        src={menuWhite}
                                        alt="menu"
                                        onClick={showSidebar}
                                    />
                                </div>
                            </div>
                        </nav>

                        <nav className={styles.ScrollBar} id="ScrollBar">
                            <div className={styles.navbarScroll}>
                                <div className={styles.logo}>
                                    <Link to="/">
                                        <img src={Logo} alt="logo" />
                                    </Link>
                                </div>
                                <div className={styles.text}>
                                    <Link
                                        to="/"
                                        className={
                                            location.pathname === '/'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        HOME
                                    </Link>
                                    <Link
                                        to="/about"
                                        className={
                                            location.pathname.slice(1) ===
                                            'about'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        ABOUT
                                    </Link>
                                    <Link
                                        to="/services"
                                        className={
                                            location.pathname.slice(1) ===
                                            'services'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        SERVICES
                                    </Link>
                                    <Link
                                        to="/affiliates"
                                        className={
                                            location.pathname.slice(1) ===
                                            'affiliates'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        AFFILIATES
                                    </Link>
                                    <Link
                                        to="/reviews"
                                        className={
                                            location.pathname.slice(1) ===
                                            'reviews'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        REVIEWS
                                    </Link>
                                    <Link
                                        to="/process"
                                        className={
                                            location.pathname.slice(1) ===
                                            'process'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        PROCESS
                                    </Link>
                                    <Link
                                        to="/career"
                                        className={
                                            location.pathname.slice(1) ===
                                            'career'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        CAREER
                                    </Link>

                                    <div className={styles.dropdownContainer}>
                                        <Link
                                            className={
                                                location.pathname.slice(1) ===
                                                    'contact' ||
                                                location.pathname.slice(1) ===
                                                    'FAQ'
                                                    ? `${styles.navitem} ${styles.navitemactive}`
                                                    : styles.navitem
                                            }
                                        >
                                            HELP
                                        </Link>
                                        <div className={styles.dropdownContent}>
                                            <Link
                                                to="/contact"
                                                className={styles.dropdownItem}
                                            >
                                                CONTACT
                                            </Link>
                                            <Link
                                                to="/FAQ"
                                                className={styles.dropdownItem}
                                            >
                                                FAQ
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.scrollMenu}>
                                    {/* <FaIcons.FaBars onClick={showSidebar} /> */}
                                    <img
                                        src={menuBlue}
                                        alt="menu"
                                        onClick={showSidebar}
                                    />
                                </div>
                            </div>
                        </nav>
                        <nav
                            className={`${
                                sidebar
                                    ? `${styles.navmenu} ${styles.active}`
                                    : styles.navmenu
                            }`}
                        >
                            <ul
                                className={styles.navMenuItems}
                                style={{ scrollBehavior: 'smooth' }}
                            >
                                {!uparrow && sidebar && (
                                    <div className={styles.downArrow}>
                                        <a
                                            href="#Follow"
                                            alt="linkdown"
                                            onClick={() => setuparrow(true)}
                                        >
                                            <BsArrowDownCircle />
                                        </a>
                                    </div>
                                )}
                                {uparrow && sidebar && (
                                    <div className={styles.upArrow}>
                                        <a
                                            href="#Top"
                                            alt="linkdown"
                                            onClick={() => setuparrow(false)}
                                        >
                                            <BsArrowUpCircle />
                                        </a>
                                    </div>
                                )}
                                <li className={styles.navbarToggle} id="Top">
                                    <IconContext.Provider
                                        value={{ color: 'white' }}
                                    >
                                        <div
                                            className={styles.menubars}
                                            onClick={showSidebar}
                                        >
                                            <AiIcons.AiOutlineClose />
                                        </div>
                                    </IconContext.Provider>
                                </li>
                                <li
                                    style={{
                                        textAlign: 'center',
                                        marginBottom: '1.5em',
                                    }}
                                >
                                    <img
                                        className={styles.logo}
                                        src={Logo}
                                        alt="logo"
                                    />
                                </li>

                                <div className={styles.container}>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link to="/" onClick={showSidebar}>
                                                <div
                                                    className={
                                                        styles.ScrollNavItem1
                                                    }
                                                >
                                                    <h3>Home</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link
                                                to="/about"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem2
                                                    }
                                                >
                                                    <h3>About</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link
                                                to="/services"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem3
                                                    }
                                                >
                                                    <h3>Services</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    {/* <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link
                                                to="/packages"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem10
                                                    }
                                                >
                                                    <h3>Packages</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div> */}
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link
                                                to="/affiliates"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem4
                                                    }
                                                >
                                                    <h3>Affiliates</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link
                                                to="/reviews"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem5
                                                    }
                                                >
                                                    <h3>Reviews</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link
                                                to="/process"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem6
                                                    }
                                                >
                                                    <h3>Process</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link
                                                to="/career"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem7
                                                    }
                                                >
                                                    <h3>Career</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link
                                                to="/contact"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem8
                                                    }
                                                >
                                                    <h3>Contact</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link
                                                to="/FAQ"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem9
                                                    }
                                                >
                                                    <h3 id="Follow">FAQ</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                </div>
                                {/* <div className={`${styles.ReadyToChat} ${styles.follow}`}>
                                    <h2>
                                        Follow Us !!
                                    </h2>
                                    <div className={styles.NavSocial}>
                                        <a href="https://www.instagram.com/murphystechnology/" id="Follow" ref={ref} target="_blank" rel="noreferrer"><AiIcons.AiOutlineInstagram /></a>
                                        <a href="https://www.facebook.com/murphysworld80" target="_blank" rel="noreferrer" ><BiIcons.BiLogoFacebook /></a>
                                    </div>
                                </div> */}
                            </ul>
                        </nav>
                    </header>
                </div>
            )}
        </>
    )
}

export default Navbar2
