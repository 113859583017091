import React from 'react'
// import TopBanner from '../../components/TopBanner/TopBanner'
import styles from '../Process/Process.module.css'
import { BsDot } from 'react-icons/bs'
import Icon1 from '../../img/Flaticon/marketAnalysis1.webp'
import Icon2 from '../../img/Flaticon/seo1.webp'
import Icon3 from '../../img/Flaticon/projectManagement1.webp'
import Magnifying from '../../img/Flaticon/DesignProcess.webp'
import UIUX from '../../img/Flaticon/requirements.webp'
import development from '../../img/Flaticon/development.webp'
import launch from '../../img/Flaticon/launch.webp'
import PostLaunch from '../../img/Flaticon/PostLAunch.webp'
import { motion } from 'framer-motion'
import GetAQuote from '../../components/GetAQuote'

import ScrollAnimation from '../../components/Animation/ScrollAnimation'
import TopBanner2 from '../../components/TopBanner/TopBanner2'
import { Helmet } from 'react-helmet'
const Process = () => {
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Fast Web Hosting Sydney: Accelerate Your Online Presence"
                </title>
                <meta
                    name="description"
                    content="Experience lightning-fast web hosting in Sydney. Accelerate your online presence with our reliable and high-speed hosting solutions. Choose speed, choose success."
                />
                <meta name="keywords" content="Fast Web Hosting Sydney" />
            </Helmet>

            <div>
                {/* <TopBanner name="Process" /> */}
                <TopBanner2 name="Process" />
                <div className={styles.outersection}>
                    <div class={styles.container}>
                        <div className={styles.HeadingHEAD}>
                            <ScrollAnimation>
                                <h4>
                                    We offer end-to-end service for your
                                    responsive website design.
                                </h4>
                            </ScrollAnimation>
                            <ScrollAnimation>
                                <h1>Key Steps</h1>
                            </ScrollAnimation>
                            <ScrollAnimation>
                                <h1>In</h1>
                            </ScrollAnimation>
                            <ScrollAnimation>
                                <h1>Web Design and Development </h1>
                            </ScrollAnimation>
                        </div>
                        <div className={styles.Steps}>
                            <div className={styles.icons1}>
                                <img src={UIUX} alt="Fast Web Hosting Sydney" />
                            </div>
                            <div className={styles.icons2}>
                                <img
                                    src={Magnifying}
                                    alt="Fast Web Hosting Sydney"
                                />
                            </div>
                            <div className={styles.icons3}>
                                <img
                                    src={development}
                                    alt="Fast Web Hosting Sydney"
                                />
                            </div>
                            <div className={styles.icons4}>
                                <img
                                    src={launch}
                                    alt="Fast Web Hosting Sydney"
                                />
                            </div>
                            <div className={styles.icons5}>
                                <img
                                    src={PostLaunch}
                                    alt="Fast Web Hosting Sydney"
                                />
                            </div>
                            <div className={styles.StepOne}>
                                <div className={styles.Background}>
                                    01
                                    <div className={styles.Heading}>
                                        <p>Discover </p>
                                        <p>your</p>
                                        <p>requirements</p>
                                    </div>
                                </div>
                                <div className={styles.glass}>
                                    <div>
                                        <div>
                                            <BsDot />
                                            <p>Market Research</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>User interviews</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>User Persona development</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Empathy Mapping</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Competitor Analysis</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Value Proposition Canvas</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <BsDot />
                                            <p>Data Analytics review</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Content strategy / migration</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>SEO Research</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>SEO Audit</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>SEO Recommendations</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.StepTwo}>
                                <div className={styles.glass}>
                                    <div>
                                        <div>
                                            <BsDot />
                                            <p>Stakeholder workshop</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Information architecture</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Wireframing</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>User Journey</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>UI Design</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <BsDot />
                                            <p>Hi-fi Prototyping</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Design system</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Animation and interaction</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Usability testing</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Accessibility testing</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.Background}>
                                    02
                                    <div className={styles.Heading}>
                                        <p>UI & UX </p>
                                        <p>Design</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.StepThree}>
                                <div className={styles.Background}>
                                    03
                                    <div className={styles.Heading}>
                                        <p>Application </p>
                                        <p>Development</p>
                                    </div>
                                </div>
                                <div className={styles.glass}>
                                    <div>
                                        <div>
                                            <BsDot />
                                            <p>System analytics and design</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>WordPress Development</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Headless Development</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Shopify Development</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <BsDot />
                                            <p>NodeJS Development</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>System integrations</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Dev Ops</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Quality assurance</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.StepFour}>
                                <div className={styles.glass}>
                                    <div>
                                        <div>
                                            <BsDot />
                                            <p>Web hosting Submission</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Website Security</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Website Speed enhancement</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <BsDot />
                                            <p>Load testing</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>DevOps Monitoring</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Analytics set up</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.Background}>
                                    04
                                    <div className={styles.Heading}>
                                        <p>Application </p>
                                        <p>Launch</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.StepFive}>
                                <div className={styles.Background}>
                                    05
                                    <div className={styles.Heading}>
                                        <p>Application </p>
                                        <p>Post-Launch</p>
                                    </div>
                                </div>

                                <div className={styles.glass}>
                                    <div>
                                        <div>
                                            <BsDot />
                                            <p>Support & maintenance</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Web hosting</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>SEO optimisation</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Paid ads</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <BsDot />
                                            <p>Analytics & dashboards</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Automation</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Website Enhancement</p>
                                        </div>
                                        <div>
                                            <BsDot />
                                            <p>Training sessions</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.consultaion}>
                    <ScrollAnimation>
                        <h4>Let's create something great together.</h4>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>Get a FREE </h1>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>30 minute Consultation</h1>
                    </ScrollAnimation>
                    <motion.div
                        className={styles.consultationitems}
                        initial={{ opacity: 0, y: 75 }}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        whileInView={{ opacity: 1, y: 0 }}
                    >
                        <div className={styles.items}>
                            <img
                                src={Icon1}
                                alt="Fast Web Hosting Sydney"
                            ></img>
                            <h2>Value Proposition</h2>
                            <p>
                                Initiate your business strategy by understanding
                                your customer, their pains and gains using the
                                value proposition canvas.
                            </p>
                        </div>
                        <div className={styles.items}>
                            <img
                                src={Icon2}
                                alt="Fast Web Hosting Sydney"
                            ></img>
                            <h2>Market Potenital</h2>
                            <p>
                                Analysing if the project is commercially and
                                technically viable using the business model
                                canvas.
                            </p>
                        </div>
                        <div className={styles.items}>
                            <img
                                src={Icon3}
                                alt="Fast Web Hosting Sydney"
                            ></img>
                            <h2>Cost & Timeframe</h2>
                            <p>
                                Providing you with a detailed proposal with an
                                analysis of the work involved and the efforts
                                required
                            </p>
                        </div>
                    </motion.div>

                    <a
                        className={styles.btn}
                        href="https://calendly.com/digital-strategists-murphy/30-minute-strategy-session"
                        id={styles.btn1}
                    >
                        Book 30 minute free Consultation
                    </a>
                </div>
                <GetAQuote />
            </div>
        </>
    )
}

export default Process
