import React, { useState } from 'react'
import styles from './AboutSection.module.css'
import MLogo from '../../img/Home/m_logo.png'
import ScrollAnimation from '../Animation/ScrollAnimation'
import { MdSmartDisplay } from 'react-icons/md'
import { createPortal } from 'react-dom'
import Modal from '../Modal/Modal'

const AboutSection = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const handleButtonClick = (value) => {
        setModalOpen(false)
    }
    // eslint-disable-next-line
    const handleVideoClick = (value) => {
        setModalOpen(true)
    }
    return (
        <div className={styles.body}>
            <div className={styles.AboutSection}>
                <div className={styles.AboutVideo}>
                    {modalOpen &&
                        createPortal(
                            <Modal
                                onClose={handleButtonClick}
                                onSubmit={handleButtonClick}
                                onCancel={handleButtonClick}
                            >
                                <div className={styles.modalDiv}>
                                    <>
                                        <iframe
                                            title="AboutVideo"
                                            src="https://fast.wistia.net/embed/iframe/1ku2wdbagc"
                                            width="720"
                                            height="440"
                                            allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                                            allowfullscreen
                                            frameborder="0"
                                        ></iframe>
                                    </>
                                </div>
                            </Modal>,
                            document.body
                        )}
                    {/* modal */}
                    <video
                        title="AboutVideo"
                        src="https://res.cloudinary.com/dba0jtzdi/video/upload/v1742976208/tk7fpsfp9utz5fxwec4k.mov"
                        autoPlay
                        loop
                        playsInline
                        muted
                        height="100%"
                        width="100%"
                    ></video>
                    <div
                        className={styles.AboutVideoOverlay}
                        onClick={() => setModalOpen(true)}
                    ></div>
                    <div
                        className={styles.AboutPlayButton}
                        onClick={() => setModalOpen(true)}
                    >
                        <MdSmartDisplay />
                    </div>
                </div>
                <div className={styles.RightSection}>
                    <ScrollAnimation>
                        <h4>
                            The Power of Storytelling Through Digital Marketing
                        </h4>
                    </ScrollAnimation>

                    <ScrollAnimation>
                        <div className={styles.aboutTitle}>
                            <h2>
                                Every business has its story. Tell your story,
                                your way.
                            </h2>
                        </div>
                    </ScrollAnimation>
                    <p>
                        Every brand faces different challenges, so each project
                        requires a tailored approach. We believe that creative
                        marketing should never follow a one-size-fits-all
                        formula. Instead, we focus on flexibility, adapting our
                        strategies to suit the specific needs of each client.
                        Our goal is to meet businesses at their current stage,
                        understand their vision, and guide them toward success
                        with innovative and customized marketing solutions.
                    </p>

                    <div className={styles.aboutSeoButton}>
                        <a
                            className={styles.btn}
                            href="https://calendly.com/digital-strategists-murphy/30-minute-strategy-session"
                            id={styles.btn1}
                        >
                            Find Out More
                        </a>
                        <a
                            className={styles.btn}
                            href="https://www.seo.murphystechnology.com.au/"
                            id={styles.btn1}
                        >
                            SEO Packages
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection
