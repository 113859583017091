import React from 'react'
import styles from './MobileApp.module.css'
import ScrollAnimation from '../Animation/ScrollAnimation'
import MLogo from '../../img/Logos/primary_logo_dark.png'
import MTlogo from '../../img/Logos/m_logo.png'
import { FaCircleCheck, FaXmark } from 'react-icons/fa6'
import DiffTable from '../../img/table1.jpg'

const MobileApp = () => {
    return (
        <div className={styles.outerDiv}>
            <div className={styles.heading}>
                <ScrollAnimation>
                    <h4>Make. Break. Visualize.</h4>
                </ScrollAnimation>
                <ScrollAnimation>
                    <h1>The MURPHYS Difference</h1>
                </ScrollAnimation>
            </div>

            <div className={styles.caseStudies}>
                <table>
                    <tr className={styles.tableHeading}>
                        <th>Here's How We Compare</th>
                        <th>
                            <img
                                src={MLogo}
                                alt="logo"
                                className={styles.TableLogo}
                            />
                            <img
                                src={MTlogo}
                                alt="logo"
                                className={styles.TableMLogo}
                            />
                        </th>
                        <th>Traditional Agency </th>
                        <th>Hiring In-House </th>
                        <th>Done With Your Service</th>
                    </tr>
                    <tr className={styles.tableBody}>
                        <td>No long term contracts</td>
                        <td className={styles.check}>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaCircleCheck />
                        </td>
                    </tr>

                    <tr className={styles.tableBody}>
                        <td>Dedicated creative strategist</td>
                        <td className={styles.check}>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                    </tr>
                    <tr className={styles.tableBody}>
                        <td>Dedicated ads design team</td>
                        <td className={styles.check}>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                    </tr>
                    <tr className={styles.tableBody}>
                        <td>High volume creative testing</td>
                        <td className={styles.check}>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                    </tr>
                    <tr className={styles.tableBody}>
                        <td>Max 10 clients per team</td>
                        <td className={styles.check}>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaCircleCheck />
                        </td>
                    </tr>
                    <tr className={styles.tableBody}>
                        <td>Australia Based team (all in house)</td>
                        <td className={styles.check}>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaCircleCheck />
                        </td>
                    </tr>
                    <tr className={styles.tableBody}>
                        <td>Complete full service solution</td>
                        <td className={styles.check}>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                    </tr>
                    <tr className={styles.tableBody}>
                        <td>You own all the assets & account data</td>
                        <td className={styles.check}>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaXmark />
                        </td>
                        <td>
                            <FaCircleCheck />
                        </td>
                        <td>
                            <FaCircleCheck />
                        </td>
                    </tr>
                </table>

                <img
                    src={DiffTable}
                    alt="difference table"
                    className={styles.caseStudiesImage}
                />
            </div>
        </div>
    )
}

export default MobileApp
