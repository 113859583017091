import React, { useEffect, useRef, useState } from 'react'
import styles from './Testimonials.module.css'
import ScrollAnimation from '../Animation/ScrollAnimation'
import Marquee from 'react-fast-marquee'
// import './marquee.css'
import airlec from '../../img/Client/airlec.png'
import bci from '../../img/Client/bci.png'
import braxton from '../../img/Client/braxton.png'
import elevate from '../../img/Client/elevate.png'
import food from '../../img/Client/food.png'
import modern from '../../img/Client/modern.png'
import picnic from '../../img/Client/picnic.png'
import ronals from '../../img/Client/ronals.png'
import thynk from '../../img/Client/thynk.png'
import { motion, useInView } from 'framer-motion'

const Testimonial = () => {
    const [progress, setProgress] = useState(0)
    const progressRef = useRef(null)
    const isInView = useInView(progressRef, { once: true })

    useEffect(() => {
        if (isInView) {
            let count = 0
            const interval = setInterval(() => {
                if (count < 99) {
                    count += 1
                    setProgress(count)
                } else {
                    clearInterval(interval)
                }
            }, 30)
        }
    }, [isInView])

    return (
        <div className={styles.testimonials}>
            <div className={styles.brandsContainer}>
                <div className={styles.Heading}>
                    <ScrollAnimation>
                        <h4>Brand Experience</h4>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>Your Story, Told Your Way</h1>
                    </ScrollAnimation>
                </div>

                <Marquee
                    className="brandMarqueeContainer"
                    direction="right"
                    speed={40}
                    pauseOnHover={true}
                >
                    <div className={styles.imageContainer1}>
                        <img src={airlec} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={bci} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={braxton} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={elevate} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={food} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={modern} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={picnic} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={ronals} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={thynk} alt="" />
                    </div>
                </Marquee>
                <Marquee
                    className="brandMarqueeContainer"
                    direction="left"
                    speed={40}
                    pauseOnHover={true}
                >
                    <div className={styles.imageContainer1}>
                        <img src={airlec} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={bci} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={braxton} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={elevate} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={food} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={modern} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={picnic} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={ronals} alt="" />
                    </div>
                    <div className={styles.imageContainer1}>
                        <img src={thynk} alt="" />
                    </div>
                </Marquee>
            </div>

            <div className={styles.newsletterContainer}>
                <div className={styles.newsletter}>
                    <div className={styles.progressContainer}>
                        <div
                            ref={progressRef}
                            style={{ width: '60%', padding: '0 0 10px 0' }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '18px',
                                    background: 'white',
                                    borderRadius: '5px',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                            >
                                <motion.div
                                    initial={{ width: 0 }}
                                    animate={{ width: `${progress}%` }}
                                    transition={{ duration: 2 }}
                                    style={{
                                        height: '18px',
                                        background: '#7fd136',
                                        borderRadius: '5px',
                                        clipPath:
                                            'polygon(0 0, 100% 0, 97% 100%, 0% 100%)',
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <h3>GET YOUR 10% OFF ON YOUR FIRST PROJECT</h3>
                    <p>
                        Get it in your inbox{' '}
                        <span className={styles.testUnder}>
                            under 2 minutes
                        </span>
                    </p>
                    <form className={styles.inputContainer}>
                        <input
                            required
                            type="email"
                            placeholder="Enter your email"
                        />
                        <button type="submit">
                            Yes, I want 10% off{' '}
                            <i class="fa-solid fa-sack-dollar fa-shake"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
